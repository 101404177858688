<template>
  <FormComponent class="register" @submit="register">
    <h3 class="auth-title">
      {{ $t('app.experience-title') }}
    </h3>
    <p style="color: lightslategray">
      {{ $t('auth.slogan') }}
    </p>
    <div class="row center">
      <div class="col-md-6 new-label">
        <InputComponent :label="$tc('app.name', 1)" name="name" :placeholder="$tc('app.name', 1)" required v-model="form.name"></InputComponent>
      </div>
      <div class="col-md-6">
        <InputComponent :label="$tc('app.company', 1)" name="company" :placeholder="$t('app.company')" required v-model="form.account_name"></InputComponent>
      </div>
      <div class="col-md-6">
        <SelectComponent :label="$tc('app.agent', 2)" :placeholder="'Quantidade de agentes'" :options="agents" required v-model="form.agents"></SelectComponent>
      </div>
      <div class="col-md-6">
        <InputComponent name="email" type="email" :label="$tc('app.corporate-email', 1)" :placeholder="$t('app.corporate-email')" required v-model="form.email"></InputComponent>
      </div>
      <div class="col-md-6">
        <TelInputComponent :label="$tc('app.phone', 1)" v-model="form.mobile_number" required></TelInputComponent>
      </div>
      <div class="col-md-6">
        <PasswordInputComponent :label="$tc('app.password', 1)" :placeholder="$t('login-invite.fullname')" required v-model="form.password"></PasswordInputComponent>
      </div>
    </div>
    <CheckboxComponent v-model="terms" required
      >Li e concordo com os
      <a id="show-btn" href="javascript:void(0)" @click="showModal()">termos de uso</a>
      <b-modal ref="my-modal" size="xl" scrollable hide-footer title="Termos">
        <div class="container-fluid">
          <ModalContentTerms />
        </div>
      </b-modal>
    </CheckboxComponent>
    <ButtonComponent type="submit" is-block :loading="isSending">{{ $t('app.experience') }}</ButtonComponent>

    <div class="row">
      <p style="color: #752DE6;
font-size: 14px;
margin-left: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;">{{ $t('auth.already-have-account') }}</p> <a href="/login" style="color: #752DE6;text-decoration: underline;font-size: 14px;margin-left: 3px;font-weight: 600;">{{ $t('generic-str.login') }}</a>
</div>

<div class="mb-4" v-if="isMainWhitelabel">
      <SeparatorComponent text="Ou" />
      <div class="d-flex align-items-center justify-content-center social-group">
        <GoogleButtonComponent @login="handleGoogleLogin"></GoogleButtonComponent>
        <FacebookButtonComponent @login="handleFacebookLogin"></FacebookButtonComponent>
      </div>
    </div>
  </FormComponent>
  <!--</form>-->
</template>

<script>
// @ is an alias to /src
import User from '@/models/user';
import { load } from 'recaptcha-v3';
import ModalContentTerms from '@/components/rebranding/ModalContentTerms.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import FormComponent from '../../components/form/FormComponent.vue';
import InputComponent from '../../components/form/InputComponent.vue';
import TelInputComponent from '../../components/form/TelInputComponent.vue';
import PasswordInputComponent from '../../components/form/PasswordInputComponent.vue';
import CheckboxComponent from '../../components/form/CheckboxComponent.vue';
import ButtonComponent from '../../components/ui/ButtonComponent.vue';
import SeparatorComponent from '../../components/ui/SeparatorComponent.vue';
import GoogleButtonComponent from '../../components/ui/GoogleButtonComponent.vue';
import FacebookButtonComponent from '../../components/ui/FacebookButtonComponent.vue';

export default {
  name: 'Register',
  components: {
    ModalContentTerms,
    FormComponent,
    InputComponent,
    TelInputComponent,
    PasswordInputComponent,
    CheckboxComponent,
    ButtonComponent,
    SeparatorComponent,
    GoogleButtonComponent,
    FacebookButtonComponent,
    SelectComponent,
  },
  computed: {
    isMainWhitelabel() {
      return this.$store.state?.admin?.whitelabel?.['is_main'] || !this.$store.state?.whitelabel;
    },
  },
  data() {
    return {
      agents: [
        {
          text: '00 a 04',
          value: '00 a 04',
        },
        {
          text: '05 a 10',
          value: '05 a 10',
        },
        {
          text: '11 a 20',
          value: '11 a 20',
        },
        {
          text: 'Acima de 20',
          value: 'Acima de 20',
        },
      ],
      user: new User('', ''),
      terms: false,
      isSending: false,
      isSendingGoogle: false,
      isSendingFacebook: false,
      form: {
        name: '',
        account_name: '',
        email: '',
        mobile_number: '',
        password: '',
        agents: '',
      },
    };
  },
  methods: {
    back() {
      this.$parent.routing = false;
      this.$router.push('/login');
    },
    recaptcha(callback) {
      load(process.env.VUE_APP_RECAPTCHA_KEY, {
        useRecaptchaNet: true,
        autoHideBadge: true,
      }).then((recaptcha) => {
        recaptcha.execute('refresh').then((token) => {
          console.log(token);
          this.user.recaptcha_token = token;
          callback();
        });
      });
    },
    register() {
      this.isSending = true;
      this.recaptcha(() => {
        this.$store.dispatch('auth/registerTrial', { ...this.form, ...this.$route.query }).then(
          () => {
            this.$store.dispatch('auth/login', this.form).then(
              (response) => {
                console.log(response);
                this.isSending = false;
                if (this.$store.state.admin.whitelabel.name === 'Yup Chat') {
                  if (window.dataLayer) {
                    window.dataLayer.push({ event: 'registered' });
                  }
                }
                this.$router.push('/');
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            );
          },
          (error) => {
            console.log(error);
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      });
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    handleGoogleLogin(response) {
      this.$store.dispatch('auth/loginWithGoogle', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
    handleFacebookLogin(response) {
      this.$store.dispatch('auth/loginWithFacebook', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.social-group {
  gap: 16px;
}

.auth-title {
  font-size: 30px;
  margin-bottom: 10px;

  .btn {
    padding: 0;
    font-size: 24px;
  }
}

::v-deep .form-group {
  label {
    color: #752DE6 !important;
    margin-bottom: 0.32rem!important;
    font-size: .785rem !important;
  }
}

::v-deep .form-group {
  label {
    span {
      display: none;
    }
  }
}

::v-deep .form-group {
  .form-control {
    border: 2px solid rgba(117, 45, 230, 0.16);
    padding: 10px 15px !important;
  }

  input[type="tel"] {
    border-right: 2px solid rgba(117, 45, 230, 0.16)!important;
    border-radius: 0px 10px 10px 0px!important;
  }

  .material-icons-outlined {
    color: gainsboro!important;
  }

  .v-select {
    .vs__dropdown-toggle {
      border-color: rgba(117, 45, 230, 0.16);
    }
  }
}

::v-deep .primary {
  height: 53px !important;
  font-weight: 600 !important;
}

::v-deep .btn-icon {
  border: 1px 1px 1px 0px;
  border-color: rgba(117, 45, 230, 0.16) !important;
}

::v-deep .dropdown-toggle {
  border: 1px 1px 1px 0px;
  border-color: rgba(117, 45, 230, 0.16) !important;
}

::v-deep .form-group {
  .v-select {
    .vs__dropdown-toggle {
      input {
        font-size: 14.3px!important;
        color: #C6CDDE!important;
      }
    }
  }
}

::v-deep .separator::before,
::v-deep .separator::after {
  border-top: 2px solid rgba(117, 45, 230, 0.16) !important;
}

::v-deep .separator {
  span {
        margin: 0px 10px;
        font-size: 15px;
  }
}

::v-deep .google-btn {
  width: 202px;
  border: 2px solid rgba(117, 45, 230, 0.16) !important;
  margin-bottom: 0px!important;
  &:hover {
    background-color: rgba(117, 45, 230, 0.16) !important;
  }
}

::v-deep .facebook-btn {
  width: 202px;
  border: 2px solid rgba(117, 45, 230, 0.16) !important;
  margin-bottom: 0px!important;
  &:hover {
    background-color: rgba(117, 45, 230, 0.16) !important;
  }
  background-color: white!important;
  i {
    color: #1877F2!important;
  }
}

h3, p {
  text-align: center;
}
</style>
